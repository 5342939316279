import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import TimeAgo from 'javascript-time-ago'

import createVerboseDateFormatter from './helpers/verboseDateFormatter'
import { getDate } from './helpers/date'

import { style as styleType } from './PropTypes'

function ReactTimeAgo({
	date,
	timeStyle,
	tooltip,
	// `container` property name is deprecated, 
	// use `wrapperComponent` property name instead.
	container,
	wrapperComponent,
	wrapperProps,
	locale,
	locales,
	formatVerboseDate,
	verboseDateFormat,
	updateInterval,
	tick,
	...rest
}) {
	// Get the list of preferred locales.
	const preferredLocales = useMemo(() => {
		// Convert `locale` to `locales`.
		if (locale) {
			locales = [locale]
		}
		// Add `javascript-time-ago` default locale.
		return locales.concat(TimeAgo.getDefaultLocale())
	}, [
		locale,
		locales
	])

	// Create `javascript-time-ago` formatter instance.
	const timeAgo = useMemo(() => new TimeAgo(preferredLocales), [preferredLocales])

	// `React.Component.forceUpdate()` analogue.
	const [unusedState, setUnusedState] = useState()
	const forceUpdate = useCallback(() => setUnusedState({}), [setUnusedState])

	const autoUpdateTimer = useRef()

	useEffect(() => {
		return () => clearTimeout(autoUpdateTimer.current)
	}, [])

	// The date or timestamp that was passed.
	// Convert timestamp to `Date`.
	date = getDate(date)

	// Create verbose date formatter for the tooltip text.
	// (only on client side, because tooltips aren't rendered 
	//  until triggered by user interaction)
	const verboseDateFormatter = useMemo(() => {
		if (typeof window !== 'undefined') {
			return createVerboseDateFormatter(preferredLocales, verboseDateFormat)
		}
	}, [
		preferredLocales,
		verboseDateFormat
	])

	// Format verbose date for the tooltip.
	// (only on client side, because tooltips aren't rendered 
	//  until triggered by user interaction)
	const verboseDate = useMemo(() => {
		if (typeof window !== 'undefined') {
			if (formatVerboseDate) {
				return formatVerboseDate(date)
			}
			return verboseDateFormatter(date)
		}
	}, [
		date,
		formatVerboseDate,
		verboseDateFormatter
	])

	// Format the date.
	const [formattedDate, _timeToNextUpdate] = timeAgo.format(date, timeStyle, {
		getTimeToNextUpdate: true
	})

	// Schedule next update.
	if (tick) {
		clearTimeout(autoUpdateTimer.current)
		const autoUpdateInterval = updateInterval || _timeToNextUpdate || 60 * 1000 // A minute by default.
		autoUpdateTimer.current = setTimeout(forceUpdate, getSafeTimeoutInterval(autoUpdateInterval))
	}

	const result = (
		<time
			dateTime={date.toISOString()}
			title={tooltip ? verboseDate : undefined} 
			{...rest}>
			{formattedDate}
		</time>
	)

	const WrapperComponent = wrapperComponent || container

	if (WrapperComponent) {
		return (
			<WrapperComponent
				{...wrapperProps}
				verboseDate={verboseDate}>
				{result}
			</WrapperComponent>
		)
	}

	return result
}

ReactTimeAgo.propTypes = {
	// The `date` or `timestamp`.
	// E.g. `new Date()` or `1355972400000`.
	date: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.number
	]).isRequired,

	// Preferred locale.
	// Is 'en' by default.
	// E.g. 'ru-RU'.
	locale: PropTypes.string,

	// Alternatively to `locale`, one could pass `locales`:
	// A list of preferred locales (ordered).
	// Will choose the first supported locale from the list.
	// E.g. `['ru-RU', 'en-GB']`.
	locales: PropTypes.arrayOf(PropTypes.string),

	// Date/time formatting style.
	// See `javascript-time-ago` docs on "Styles" for more info.
	// E.g. 'round', 'round-minute', 'twitter', 'twitter-first-minute'.
	timeStyle: styleType,

	// Whether to use HTML `tooltip` attribute to show a verbose date tooltip.
	// Is `true` by default.
	// Can be set to `false` to disable the native HTML `tooltip`.
	tooltip: PropTypes.bool.isRequired,

	// Verbose date formatter.
	// By default it's `(date) => new Intl.DateTimeFormat(locale, {…}).format(date)`.
	formatVerboseDate: PropTypes.func,

	// `Intl.DateTimeFormat` format for formatting verbose date.
	// See `Intl.DateTimeFormat` docs for more info.
	verboseDateFormat: PropTypes.object,

	// (deprecated)
	// How often the component refreshes itself.
	// Instead, consider using `getNextTimeToUpdate()` feature
	// of `javascript-time-ago` styles.
	updateInterval: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.shape({
			threshold: PropTypes.number,
			interval: PropTypes.number.isRequired
		}))
	]),

	// (deprecated).
	// Set to `false` to disable automatic refresh of the component.
	// Is `true` by default.
	// I guess no one actually turns that off.
	tick: PropTypes.bool,

	// (advanced)
	// A React Component to wrap the resulting `<time/>` React Element.
	// Receives `verboseDate` and `children` properties.
	// Also receives `wrapperProps`, if they're passed.
	// `verboseDate` can be used for displaying verbose date label
	// in an "on mouse over" (or "on touch") tooltip.
	// See the "Tooltip" readme section for more info.
	// Another example could be having `wrapperComponent`
	// being rerendered every time the component refreshes itself.
	wrapperComponent: PropTypes.func,

	// Custom `props` passed to `wrapperComponent`.
	wrapperProps: PropTypes.object
}

ReactTimeAgo.defaultProps = {
	// No preferred locales.
	locales: [],

	// Use HTML `tooltip` attribute to show a verbose date tooltip.
	tooltip: true,

	// `Intl.DateTimeFormat` for verbose date.
	// Example: "Thursday, December 20, 2012, 7:00:00 AM GMT+4"
	verboseDateFormat: {
		weekday: 'long',
		day: 'numeric',
		month: 'long',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		second: '2-digit',
		// timeZoneName: 'short'
	},

	// Automatically refreshes itself.
	tick: true
}

// The component schedules a next refresh every time it renders.
// There's no need to rerender this component unless its props change.
ReactTimeAgo = React.memo(ReactTimeAgo)

export default ReactTimeAgo

// `setTimeout()` has a bug where it fires immediately
// when the interval is longer than about `24.85` days.
// https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
const SET_TIMEOUT_MAX_SAFE_INTERVAL = 2147483647
function getSafeTimeoutInterval(interval) {
  return Math.min(interval, SET_TIMEOUT_MAX_SAFE_INTERVAL)
}